import SCIENCE_0 from '@/assets/images/technology/0.png';
import SCIENCE_1 from '@/assets/images/technology/1.png';
import SCIENCE_2 from '@/assets/images/technology/2.png';
import SCIENCE_3 from '@/assets/images/technology/3.png';

import PRODUCT_0 from '@/assets/images/products/respirology.png';
import PRODUCT_1 from '@/assets/images/products/cardiology.png';
import PRODUCT_2 from '@/assets/images/products/gastroenterology.png';
import PRODUCT_3 from '@/assets/images/products/oncology.png';

import NEW_1 from '@/assets/images/news/n1.png';
import NEW_2 from '@/assets/images/news/n2.png';
import NEW_3 from '@/assets/images/news/n3.png';

import {onTranslate} from '@/utils';

// nav
export const NAV_LIST = [
  {id: 'about', name: onTranslate('About Us', 0), path: '#about'},
  {
    id: 'science',
    name: onTranslate('Science', 61),
    path: '#science',
    sub: [
      {id: 'technology', name: onTranslate('Technology', 10), path: '#technology'},
      {id: 'solution', name: onTranslate('Solution', 19), path: '#products'},
    ]
  },
  {id: 'news', name: onTranslate('News Room', 60), path: '#news'},
  {
    id: 'careers',
    name: onTranslate('Careers', 58),
    path: '#careers',
    sub: [
      {id: 'values', name: onTranslate('Our Values', 42), path: '#values'},
      {id: 'culture', name: onTranslate('Our Culture', 51), path: '#culture'},
    ]
  },
  {id: 'contact', name: onTranslate('Contact', 59), path: '#contact'}
]
export const TRANSLATE_DATA = {
  id: 'translate',
  name: 'translate',
  path: '#translate',
  sub: [
    {id: 'en', name: 'English', path: '/'},
    // {id: 'cn', name: '中文/简体', path: '/'},
  ]
}

// about
export const ABOUT_DATA = {
  header: onTranslate('ABOUT US', 0),
  section: onTranslate('Redefining diagnosis, treatment, and care with prescription digital therapeutics (PDT), big data driven patient management and next-generation-sequencing (NGS) in disease areas related to cigarette use disorder', 1),
  btnText: onTranslate('READ MORE', 2),
}
export const APPROACH_DATA = {
  header: onTranslate('Approach', 5),
  section: [
    {
      header: onTranslate('Intelligent Information Processing', 6),
      article: onTranslate("We believe that Intelligent Information Processing is to direct people's attention on the things that are useful and away from those that distract. To apply that notion in today's healthcare, we strive to provide patients with engagement maximizing solutions in conjunction with cognitive behavior therapy in order to subvert the lazy human nature, which is the number one hurdle in chronic disease home management", 7),
    },
    {
      header: onTranslate('Sky is the Limit', 8),
      article: onTranslate('We start off with experienced multidisciplinary industry veterans, who are healthcare authorities on many levels, combining data and experience to build an AI platform that would translate advanced medicine into patient welfare and business value. We entitle the engine to iterate autonomously, and its stakeholders the freedom to generate and implement arbitrary protocols, then wait to see what greatness could emerge', 9),
    }
  ]
}
export const ABOUT_INFO = [
  onTranslate('Leaflong Therapeutics is a pioneer in redefining medical treatment with prescription digital therapeutics and data driven patient care in disease areas related to cigarette usage. Our cross-functional team is comprised of global medical academic leaders, algorithm scientists and geneticist. We operate at the intersection of medicine, AI technology and bioinformatics. Leaflong aspires to explore, develop, and deliver clinically validated, individualized, and affordable medical services to patients in need in a frictionless fashion', 3),
  onTranslate('Unlike many peers who try to provide treatment that is independent from traditional medical system, Leaflong works closely with public and private healthcare institutions by providing best-in-class AI-driven clinical data platform across multiple indications that help doctors and nurses in every aspect from medical practice efficiency, clinical data processing, to patient health monitoring. Through collaborative research and development, we create first-in-class diagnosis, treatment and care products with the best respirologist, cardiologist, gastroenterologist, and oncologists who draft the Chinese and global clinical guidelines among various indications', 4)
]

// science
export const SCIENCE_Technology = {
  sub: onTranslate('SCIENCE', 61),
  header: onTranslate('TECHNOLOGY', 10)
}
export const SCIENCE_DATA = [
  {
    id: 0,
    key: 'EMR',
    title: onTranslate('Intelligent EMR Processing Database', 11),
    content: onTranslate('We conduct in-depth structured extraction, analysis, and inference of EMR through relationship extraction, event extraction, knowledge chain and more, along with refined valuable information from unstructured data filtered by academic leaders to support the construction of special disease database', 12),
    icon: SCIENCE_0
  },
  {
    id: 1,
    key: 'System',
    title: onTranslate('Deep Structure Chronic Disease Management System', 13),
    content: onTranslate('We establish standards for special disease clinical research data, and treatment and care pathways under the guidance of global academic leaders by following national medical data security policy and institutional compliance. Our system is highly compatible with external ports that supports multi-source-data (HIS, LIS, PACS, EMRs) and multi-data-center connection and sharing, which allows us to provide the patients with a close-loop disease management eco-system, in conjunction with medicine, that offers seamless user experience for home follow-up, care, monitoring and more for diverse cohorts', 14),
    icon: SCIENCE_1
  },
  {
    id: 2,
    key: 'Translational',
    title: onTranslate('Translational Medicine Platform', 15),
    content: onTranslate('We bridge across the discovery, development, regulation, and utilization spectrum. We apply research findings from genes, proteins, cells, tissues, to clinical research in patient populations, all aimed at optimizing and predicting outcomes in patients with cigarette use disorder. Especially, we leverage bioinformatics to provide patients with a complete chronic disease management eco-system from disease screening, diagnostics to treatment and care', 16),
    icon: SCIENCE_2
  },
  {
    id: 3,
    key: 'Lab',
    title: onTranslate('Digital Biomarkers Lab', 17),
    content: onTranslate('We track objective, quantifiable physiological and behavioral data that is collected and measured by in-house designed portable, expiratory or implantable digital devices. We run millions of tests on our database every year to discover and apply digital biomarkers that represent opportunities to capture data that is clinically meaningful, objective, and accessible with low friction. With our AI model analysis, the digital biomarkers collected are typically used to explain, influence and/or predict special-disease-related therapeutic outcomes', 18),
    icon: SCIENCE_3
  }
]
export const SCIENCE_Solution = {
  sub: onTranslate('SCIENCE', 61),
  header: onTranslate('SOLUTION', 19),
  content: [
    onTranslate('Implementation', 24),
    onTranslate('LeafDTx', 26),
    onTranslate('AI Driven Disease', 30),
    onTranslate('Screening and Diagnostics', -1),
    onTranslate('Clinically Validated Personalized', 31),
    onTranslate('Disease Management', -1),
    onTranslate('Clinical-Research-Grade Home', 32),
    onTranslate('Follow-Up and Intervention', -1),
    onTranslate('Interation', 25),
    onTranslate('PATIENTS', 27),
    onTranslate('Interagration & Processing', 33),
    onTranslate('Collection & Structuralization', 34),
    onTranslate('Quality Control & Security', 25),
    onTranslate('Interation', 25),
    onTranslate('LeafSOL', 29),
    onTranslate('System Driven Patient',36),
    onTranslate('Management', -1),
    onTranslate('EDC for RWS/RCT and More', 37),
    onTranslate('Digitized Administration', 38),
    onTranslate('Implementation', 24),
    onTranslate('INSTITUTIONS', 28)
  ]
}
export const PRODUCT_DATA = [
  {id: 'respirology', name: onTranslate('RESPIROLOGY', 20), img: PRODUCT_0},
  {id: 'cardiology', name: onTranslate('CARDIOLOGY', 21), img: PRODUCT_1},
  {id: 'gastroenterology', name: onTranslate('GASTROENTEROLOGY', 22), img: PRODUCT_2},
  {id: 'oncology', name: onTranslate('ONCOLOGY', 23), img: PRODUCT_3}
]

// news
export const NEWS = {
  btnText: onTranslate('MORE', 2)
}
export const NEWS_DATA = [
  {
    id: 'new1',
    title: <h2>2022年世界无烟日｜烟草威胁环境,吸烟危害生命</h2>,
    content: 'World No Tobacco Day ｜ Tabacco Threatens the Environment,Smoking Endangers Lives',
    desc: '',
    showImg: require('@/assets/images/news/new0.png'),
    selectImg: require('@/assets/images/news/new0.png'),
    head: '2022年世界无烟日｜烟草威胁环境,吸烟危害生命',
    href: 'https://mp.weixin.qq.com/s/wFgLshFTzbjfl1-xlbTvCw',
    articles: [
      '2022年5月31日,是第35个世界无烟日,今年世界无烟日的主题为“烟草威胁环境”。世界卫生组织展示了烟草“从种植到生产到分销到废物处理”的整个生命周期都在对我们赖以生存的地球环境造成严重污染,同时也损害了社会和经济。让我们一起通过下图来了解一下吧。',
      <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new2.1.png')} /></div>,
      '除了对环境的破坏,吸烟有害健康已经是一个不争的医学事实。全球每年有800多万人死于烟草,其中超过120万人是受二手烟危害的非吸烟者。在我国,大约每30秒就有一人因吸烟而死亡。烟草烟雾中含有7000多种化学物质,其中数百种对人体有害,致癌物高达69种,吸烟时,这些化学成分能够快速地从肺部进入血液,并遍布全身的组织器官,对身体产生危害。吸烟可导致癌症、心脑血管疾病、增加心肌梗死或脑卒中风险、损伤呼吸道和肺部、影响生育等各种疾病。',
      <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new2.2.png')} /></div>,
      '近日,一份我国烟草依赖的流行状况最新研究结果显示,我国吸烟者总数约3.08亿,其中约1.835亿吸烟者患有烟草依赖,已经超过了慢性阻塞性肺疾病、哮喘、冠心病、脑卒中、糖尿病等常见慢性疾病患者人数。也就是说平均每2位吸烟者中,就有1位患有烟草依赖。这组惊人的数字不得不引起高度重视,那么什么是烟草依赖？',
      <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new2.3.png')} /></div>,
      '烟草依赖是一种致命的慢性非传染性疾病,具有高复发的特点,一般被称为吸烟成瘾。临床表现为躯体依赖和心理依赖两个方面。躯体依赖表现为,吸烟者在停止吸烟或减少吸烟后,出现一系列难以忍受的戒断症状,包括吸烟渴求、焦虑、抑郁、不安、头痛、注意力不集中、睡眠障碍等。一般情况下,戒断症状可在停止吸烟数小时开始出现,在戒烟最初的14天内表现最强烈,之后逐渐减轻,直至消失。大多数戒断症状持续时间为1个月左右,部分患者的渴求会持续1年以上。心理依赖也称为精神依赖,表现为主观上强烈渴求吸烟,例如,早上起床后的第一件事就是抽烟。',
      '烟草依赖不是习惯,而是一种病,需要治疗。如何判定是否患有烟草依赖呢？根据中华人民共和国国家卫生和计划生育委员会。中国临床戒烟指南（2015版）,在过去1年内体验过或表现出下列6项中的至少3项,可诊断为烟草依赖：',
      <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new2.4.png')} /></div>,
      '对于这些患有烟草依赖的吸烟者们来说,患病风险非常大,他们尤其需要尽早戒烟,但相较于非烟草依赖者,戒烟成功率更低。由于强烈的“瘾”,在戒烟的过程中会遇到更多的困难和挑战,吸烟量越大,烟龄越长,戒烟成功率也就越低。那怎么办？',
      '通常,烟草依赖者需要依靠专业化的戒烟治疗才能提高戒烟的成功率。Leaflong Therapeutics的产品QuitIt（戒呗）就是一款为尼古丁成瘾患者提供的科学、高效、可以轻松控制烟瘾的数字疗法产品。',
      'QuitIt的优势在于:',
      <ul>
        <li>以机器学习、大数据、智能传感器为底层技术框架;</li>
        <li>基于强效循证干预体系（认知行为疗法、接纳与承诺疗法、积极心理疗法）;</li>
        <li>为患者提供个性化的控烟治疗方案;</li>
        <li>通过轻松有趣的的沉浸式游戏体验和简单易操作的任务形式,使治疗效果最大化;</li>
        <li>保证无障碍的医患沟通渠道;</li>
        <li>全程科学干预、跟踪随访、高效管理病情,从根本上提高戒烟的成功率;</li>
      </ul>,
      <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new2.5.png')} /></div>,
      '烟草依赖者普遍吸烟时间长,缺乏正确的戒烟知识,戒烟动力不足容易放弃,QuitIt（戒呗）的出现正好解决这一难题。不管是为了保护我们本来就稀缺的环境资源,还是为了自己和家人的健康着想,请从现在开始,放下手中的香烟,不再让烟草剥夺大家享有美好生活的权利。',
      '记住,任何人在任何年龄戒烟均可获益,且戒烟越早、持续时间越长,健康获益就越大。'
    ],
    img: require('@/assets/images/news/new0.png')
  },
  {
    id: 'new2',
    content: 'Focusing on the Digital Therapeutics in Disease Areas Related to Cigarette Usage, Leaflong Therapeutics Appeared at the Cross Strait Respiratory…',
    desc: '',
    showImg: require('@/assets/images/news/new2.png'),
    selectImg: require('@/assets/images/news/new2.png'),
    head: '聚焦烟草危害相关疾病数字疗法,Leaflong Therapeutics携创新控烟解决方案亮相海峡两岸呼吸疾病高峰论坛',
    href: 'https://www.vbdata.cn/54609',
    articles: [
      '2022年4月1-3日, “2022年第五届海峡两岸医药卫生交流协会呼吸病学专业委员会学术年会暨海峡两岸呼吸疾病高峰论坛”正式召开,会议本着“新高端、新常态、新品牌”的方针,吸纳了来自海峡两岸以及世界各地的近百位顶级专家学者与会做出专业报告分享,展示了海峡两岸呼吸病学最新研究成果。',
      <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new1.1.png')} /></div>,
      '会上,广州国家实验室主任、国家呼吸医学中心钟南山院士作题为“中国新冠疫情及防控特点”的报告,阐述了我国目前新冠疫情及其对应防控措施的特点；中国工程院副院长、中国医学科学院北京协和医学院院校长王辰院士在会上做了“关于大医学大卫生大健康”的学术报告。',
      '在呼吸疾病治疗方面,数字疗法作为一种新兴治疗手段,在本次会议上也备受瞩目。作为支持协同本次会议的优秀医药企业之一,Leaflong Therapeutics（朗叶医疗）近年来正投身于研发和生产用于治疗呼吸疾病的数字疗法产品,以数字戒烟产品QuitIt（戒呗）为例,为烟瘾患者提供了一种全新的戒烟解决方案。',
      '在本次控烟分会场的专家学术报告介绍中,朗叶医疗联合国内顶级烟草病学专家一同研发的国内首款控烟数字疗法,以机器学习、大数据、智能传感器为底层技术框架,基于强效循证干预体系,依托于门诊并融合基础慢病及不良反应的个性化诊治,为尼古丁成瘾患者提供一款科学、高效、轻松控制烟瘾的数字疗法产品；可有效提高患者依从性及控制效果,为患有烟草危害相关慢性疾病的患者带来长久的获益。',
      <div style={{textAlign: 'center'}}>
        <img width='50%' src={require('@/assets/images/news/new1.2.png')} />
        <img width='28%' src={require('@/assets/images/news/new1.3.png')} />
      </div>,
      <h2>关于Leaflong Therapeutics（朗叶医疗）</h2>,
      '朗叶医疗是烟草危害相关疾病领域数字化患者管理和疗法的开拓者,专注于将软硬件工程和生物医疗完美融合,为患者打造个性化且经过临床验证的下一代治疗方案。公司由国内外学科带头人组成的专业科学顾问委员会与算法工程师一起,将不断的对产品进行迭代更新、探索未知,追求更好更完美的医疗方式,帮助病患获得健康人生。'
    ],
    img: require('@/assets/images/news/new2.png')
  },
  {
    id: 'new3',
    content: 'Digital Therapeutics Industry Research Report 2022',
    desc: '',
    showImg: require('@/assets/images/news/empty.png'),
    selectImg: require('@/assets/images/news/empty.png'),
    head: '《2022年数字疗法行业研究报告》：行业处于早期阶段,商业繁荣应紧抓国家卫生健康事业发展机遇——基于三大领域数字疗法企业案例分析',
    href: 'https://mp.weixin.qq.com/s/s-5BnMiJLZJTimyoX7Ar_A',
    // articles: [
    //   '2022年4月1-3日, “2022年第五届海峡两岸医药卫生交流协会呼吸病学专业委员会学术年会暨海峡两岸呼吸疾病高峰论坛”正式召开,会议本着“新高端、新常态、新品牌”的方针,吸纳了来自海峡两岸以及世界各地的近百位顶级专家学者与会做出专业报告分享,展示了海峡两岸呼吸病学最新研究成果。',
    //   <div style={{textAlign: 'center'}}><img width='50%' src={require('@/assets/images/news/new1.1.png')} /></div>,
    //   '会上,广州国家实验室主任、国家呼吸医学中心钟南山院士作题为“中国新冠疫情及防控特点”的报告,阐述了我国目前新冠疫情及其对应防控措施的特点；中国工程院副院长、中国医学科学院北京协和医学院院校长王辰院士在会上做了“关于大医学大卫生大健康”的学术报告。',
    //   '在呼吸疾病治疗方面,数字疗法作为一种新兴治疗手段,在本次会议上也备受瞩目。作为支持协同本次会议的优秀医药企业之一,Leaflong Therapeutics（朗叶医疗）近年来正投身于研发和生产用于治疗呼吸疾病的数字疗法产品,以数字戒烟产品QuitIt（戒呗）为例,为烟瘾患者提供了一种全新的戒烟解决方案。',
    //   '在本次控烟分会场的专家学术报告介绍中,朗叶医疗联合国内顶级烟草病学专家一同研发的国内首款控烟数字疗法,以机器学习、大数据、智能传感器为底层技术框架,基于强效循证干预体系,依托于门诊并融合基础慢病及不良反应的个性化诊治,为尼古丁成瘾患者提供一款科学、高效、轻松控制烟瘾的数字疗法产品；可有效提高患者依从性及控制效果,为患有烟草危害相关慢性疾病的患者带来长久的获益。',
    //   <div style={{textAlign: 'center'}}>
    //     <img width='50%' src={require('@/assets/images/news/new1.2.png')} />
    //     <img width='28%' src={require('@/assets/images/news/new1.3.png')} />
    //   </div>,
    //   <h2>关于Leaflong Therapeutics（朗叶医疗）</h2>,
    //   '朗叶医疗是烟草危害相关疾病领域数字化患者管理和疗法的开拓者,专注于将软硬件工程和生物医疗完美融合,为患者打造个性化且经过临床验证的下一代治疗方案。公司由国内外学科带头人组成的专业科学顾问委员会与算法工程师一起,将不断的对产品进行迭代更新、探索未知,追求更好更完美的医疗方式,帮助病患获得健康人生。'
    // ],
    img: require('@/assets/images/news/empty.png')
  },
]

// careers
export const CAREERS = [
  onTranslate('VISION', 40),
  onTranslate('Stop tobacco from destroying our lives', 41),
  onTranslate('CAREERS', 58),
  onTranslate('OUR VALUES', 42),
  onTranslate('OUR CULTURE', 51),
  onTranslate('CONTACT US', 59)
]
export const CAREERS_DATA = [
  {
    id: 'innovation',
    name: onTranslate('Innovation', 43),
    img: require('@/assets/images/careers/c1.png'),
    desc: onTranslate('We are passionate about making difference and impact with safe and effective digital therapies to patients in need.', 44)
  },
  {
    id: 'performance',
    name: onTranslate('Performance', 45),
    img: require('@/assets/images/careers/c2.png'),
    desc: onTranslate('Our ability to invent and apply new technologies evolve as we gain in-depth understanding of the diverse cohorts through our services. ', 46)
  },
  {
    id: 'ownership',
    name: onTranslate('Ownership', 47),
    img: require('@/assets/images/careers/c3.png'),
    desc: onTranslate('We never lose sight of why our work matters – the potential to impact the lives of patients is important to everyone at Leaflong.', 48)
  },
  {
    id: 'curiosity',
    name: onTranslate('Curiosity', 49),
    img: require('@/assets/images/careers/c4.png'),
    desc: onTranslate('We are always at the state of curiosity. We believe it is the intellectual curiosity that set us to drive and solve problems.', 50)
  }
]
export const CULTURE_DATA = [
  {
    id: 1,
    header: onTranslate('Knowledge has No Limit', 52),
    content: onTranslate('The pace of change and velocity of the research and development of digital therapeutics force us to stay hungry and stay foolish and continuously pick up new knowledge, experiment with new technologies. Having the best scientists, doctors, and developers on board, we are committed in pursuit of our goal to explore the most advanced digital medicine and translate them into novel therapeutic products.', 53)
  },
  {
    id: 2,
    header: onTranslate('Strive for Excellence', 54),
    content: onTranslate('As we push the bounds of scientific exploration, we understand there will be obstacles, setbacks, and even failure. Despite how the road of digital therapeutics twist and turns, every Leaflong member remains resilient and supportive of one another. Strive for excellence, innovate for the future. Leaflong employees will remain dedicated and committed to the pursuit of excellence and innovation. ', 55)
  },
  {
    id: 3,
    header: onTranslate('Smile with Optimism ', 56),
    content: onTranslate("At Leaflong, there's life at work and life outside of work. We achieve career goals, gain happiness, and feel loved. Healthy, optimism, friendship, teamwork is what everyone here values, we are proud of our supportive and inclusive culture, and feel blessed to have such a strong and caring family to back us up. We have an incredibly exciting journey ahead of us.", 57)
  },
]
import {NEWS_DATA} from '@/mock';
import {useSearchParams} from 'react-router-dom';
import './index.scss';


const Detail = () => {

  let [searchParams] = useSearchParams();
  let index = +(searchParams.get('index') || 0);
  const data = NEWS_DATA[index];
  const {articles = [], head = ''} = data;

  return (
    <div className='detail-wrapper'>

      <header></header>

      <article className='detail-container'>

        <header>{head}</header>

        <section>

          {
            articles.map((article, index) => (
              <article key={`article-${index}`}>{article}</article>
            ))
          }

          <footer></footer>

        </section>

      </article>

    </div>
  )
}

export default Detail;
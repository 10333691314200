import {
  SCIENCE_Technology,
  SCIENCE_DATA,
  SCIENCE_Solution,
  PRODUCT_DATA
} from '@/mock';

import Switchover from '@/components/Switchover';
import './index.scss';



type ScienceProps = {
  id: string;
  className?: string;
}
export const ScienceTechnology = (props: ScienceProps) => {

  return (
    <div id={props.id} className='science-wrapper'>

      <header className='science-head'>
        <div className='science-head-sub-title'>{SCIENCE_Technology.sub}</div>
        <h2 className='science-head-title'>{SCIENCE_Technology.header}</h2>
      </header>

      <div className='science-technology-masked'>
        <Switchover sourceData={SCIENCE_DATA} />
      </div>

    </div>
  )
}


export const ScienceSolution = (props: ScienceProps) => {

  return (
    <div id={props.id} className='science-wrapper'>

      <header className='science-head'>
        <div className='science-head-sub-title'>{SCIENCE_Solution.sub}</div>
        <h2 className='science-head-title'>{SCIENCE_Solution.header}</h2>
      </header>

      <div className='solution-masked'>

        <div className='solution-container'>

          <ul className='solution-container-product'>
            {
              PRODUCT_DATA.map(item => (
                <li className='solution-container-item' key={item.id}>
                  <div className='solution-container-item-img'>
                    <img src={item.img} alt={item.name} />
                  </div>
                  <span className='solution-container-item-name'>{item.name}</span>
                </li>
              ))
            }
          </ul>

        </div>

        <div className='solution-content-wrapper'>

          <div className='solution-content'>

            <div className='solution-content-left'>

              <div className='solution-content-left-top'>
                <div>{SCIENCE_Solution.content[0]}</div>
                <p>{SCIENCE_Solution.content[1]}</p>
              </div>

              <div className='solution-content-left-item item1'>
                <div>
                  <p>{SCIENCE_Solution.content[2]}</p>
                  <p>{SCIENCE_Solution.content[3]}</p>
                </div>
                <img src={require('@/assets/images/products/set-blue.png')} alt='set' />
              </div>

              <div className='solution-content-left-item item2'>
                <div>
                  <p>{SCIENCE_Solution.content[4]}</p>
                  <p>{SCIENCE_Solution.content[5]}</p>
                </div>
                <img src={require('@/assets/images/products/set-blue.png')} alt='set' />
              </div>

              <div className='solution-content-left-item item3'>
                <div>
                  <p>{SCIENCE_Solution.content[6]}</p>
                  <p>{SCIENCE_Solution.content[7]}</p>
                </div>
                <img src={require('@/assets/images/products/set-blue.png')} alt='set' />
              </div>

              <div className='solution-content-left-bottom'>
                <div>{SCIENCE_Solution.content[8]}</div>
                <p>{SCIENCE_Solution.content[9]}</p>
              </div>

            </div>

            <div className='solution-content-center'>

              <img width='149px' src={require('@/assets/images/products/leafdata.png')} alt="leafdata" />

              <p>{SCIENCE_Solution.content[10]}</p>

              <p>{SCIENCE_Solution.content[11]}</p>

              <p>{SCIENCE_Solution.content[12]}</p>

            </div>

            <div className='solution-content-right'>

              <div className='solution-content-right-top'>
                <div>{SCIENCE_Solution.content[13]}</div>
                <p>{SCIENCE_Solution.content[14]}</p>
              </div>

              <div className='solution-content-right-item item4'>
                <img src={require('@/assets/images/products/set-red.png')} alt='set' />
                <div>
                  <p>{SCIENCE_Solution.content[15]}</p>
                  <p>{SCIENCE_Solution.content[16]}</p>
                </div>
              </div>

              <div className='solution-content-right-item item5'>
                <img src={require('@/assets/images/products/set-red.png')} alt='set' />
                <div>
                  <p>{SCIENCE_Solution.content[17]}</p>
                </div>
              </div>

              <div className='solution-content-right-item item6'>
                <img src={require('@/assets/images/products/set-red.png')} alt='set' />
                <div>
                  <p>{SCIENCE_Solution.content[18]}</p>
                </div>
              </div>

              <div className='solution-content-right-bottom'>
                <div>{SCIENCE_Solution.content[19]}</div>
                <p>{SCIENCE_Solution.content[20]}</p>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}
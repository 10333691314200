const DATA = [
  /* 0.About Us */
  '关于我们',
  /* 1.Redefining diagnosis... */
  '朗叶医疗是烟草危害相关疾病领域数字疗法的开拓者。我们致力于发展处方数字疗法,利用大数据和第二代测序技术重新定义诊断、治疗和护理,为患者提供全生命周期的健康管理方案,帮助人们收获美好人生。',
  /* 2.READ MORE */
  '更多',
  /* 3.Leaflong Therapeutics is...  */
  '朗叶医疗是烟草危害相关疾病领域数字疗法的开拓者。公司联合国内顶级烟草病学专家共同研发,与临床和科研专家以及算法工程师一起将软硬件工程和生物医疗完美的融合到一起,创造了崭新的、为患者量身打造的下一代治疗方法,让科技最大程度赋能医疗服务,从而更便捷的服务用户,提高患者参与度,从根本上提升患者医从性,节省医疗费用,帮助所有患者真正做到科学干预、高效管理、安心治疗。未来,我们将不断的迭代更新、探索未知,追求更好更完美的医疗方式。',
  /* 4.Unlike many peers... */
  '不同于其他企业,朗叶十分注重与公立和私立医疗机构的密切合作。我们尊重传统医疗方案,并以此为基础提供了涵盖多种适应症的一流AI驱动的临床数据平台,旨在帮助医护工作者提高工作效率,包括从处理临床数据到监测患者健康状况等各个方面。朗叶医疗主要聚焦在呼吸道疾病、心血管病、胃肠病和肿瘤四大领域,通过与各领域内顶尖专家的深度合作,基于强效循证干预体系,融合权威专家几十年的临床经验,与人工智能相结合,研发出可诊断、治疗和护理的国内首款戒烟数字疗法产品,为患有烟草危害相关慢性疾病的患者带来长久的获益。',
  /* 5.Approach */
  '理念',
  /* 6.Intelligent Information... */
  '智能信息处理',
  /* 7.We believe that... */
  '我们相信智能信息处理可以帮助人们将注意力专注到有用的事情上,为了推动这一理念应用于医疗健康领域,我们致力于最大化提高患者的参与度,结合认知行为疗法通过改变患者对疾病的认知进而改变患者的行为,以解决患者心理动力不足的这一慢性疾病在院外管理中的最大障碍,从根本上提高患者医从性,进而有效提升患者的生存获益。',
  /* 8.Sky is the... */
  '无可限量',
  /* 9.We start off with... */
  '朗叶拥有一支学术背景深厚、行业研发服务经验丰富的临床研究团队,我们将各领域权威专家数十年的临床经验与最新的IT技术结合起来,建立了独有的人工智能平台,让数字化技术助力医学为患者带来福祉,为企业创造价值。面临当今社会不断变化的复杂挑战,我们专注于科学创新,让人工智能及相关技术应用到医疗领域并不断完善,相信科学必将能激发出无限可能,让每一个人都可以尽情拥抱生活。',
  /* 10.TECHNOLOGY */
  '技术方案',
  /* 11.Intelligent EMR... */
  '智能EMR数据库处理',
  /* 12.We conduct in-depth... */
  '我们通过关系抽取、事件抽取、知识链管理等方式对EMR进行深入地、结构化地提取、分析和推断,结合由学科带头人筛选的非结构化数据中提炼出的有价值的信息,构建专病数据库。',
  /* 13.Deep Structure... */
  '深层结构慢性病管理系统',
  /* 14.We establish standards... */
  '我们严格遵循国家医疗数据安全政策和合规制度,在全球顶级专家学者们的引领下,建立了一套用于研究临床数据、治疗、管理的专病防控体系。我们的系统与外部端口完美兼容,支持多源数据(HIS、LIS、PACS、EMRs)和多数据中心连接共享,为患者提供以“疾病预防、治疗、院外管理”为闭环的一体化全病程慢病管理系统,连接慢性病患者的健康医疗信息,为不同群体提供跟踪随访、康复管理、智能干预、体征数据监测等全方位健康服务,切实保障患者的身体健康状况。',
  /* 15.Translational... */
  '转化医学平台',
  /* 16.We bridge across... */
  '我们突破了从“实验室到病床”中的发现、研发、规则和运用一系列障碍分隔,充分发挥各自优势,通力合作,致力于将基因组学、蛋白组学、细胞学、组织学等课题的基础研究成果转化成为实际为患者提供的真正治疗手段,旨在有效预测和合理优化烟草给人们健康带来的危害。我们充分运用生物信息学等各种技术为患者提供从疾病筛查、诊断到个体化治疗和护理的全病程慢病管理生态系统。',
  /* 17.Digital Biomarkers Lab */
  '数字生物标志物实验室',
  /* 18.We track objective... */
  '我们自主设计便携式、呼气式和植入式的医疗数字设备,纵向追踪个人健康信息,并持续收集可量化的生理和行为的客观数据,每年在数据模型中进行数百万次分析,以及时发现和合理应用数字生物标记物,这些研究有希望作为具有临床意义的、客观的、可用来预测疾病和健康管理的新一代数字生物标记物。通过人工智能模型进行分析,可产生新洞察来揭示群体的,特别是个体的身心健康和发展趋势,以解释、影响或预测专病治疗结果。',
  /* 19.PRODUCTS */
  '解决方案',
  /* 20.RESPIROLOGY */
  '呼吸病学',
  /* 21.CARDIOLOGY */
  '心血管疾病',
  /* 22.GASTROENTEROLOGY */
  '胃肠病学',
  /* 23.ONCOLOGY */
  '肿瘤学',
  /* 24.Implementation */
  '实施',
  /* 25.Interation */
  '迭代',
  /* 26.Leaf Therapeutics */
  '朗叶医疗',
  /* 27.PATIENTS */
  '患者',
  /* 28.INSTITUTIONS */
  '医疗服务机构',
  /* 29.Leaf Solutions */
  '解决方案',
  /* 30.AI driven disease... */
  'AI驱动疾病筛查和诊断',
  /* 31.Clinically validated... */
  '临床验证、个性化定制',
  /* 32.Clinical-research-grade... */
  '随访及干预的临床研究',
  /* 33.Interagration & Processing */
  '一体化管理&智能处理',
  /* 34.Collection & Structuralization */
  '数据集成&结构化分析',
  /* 35.Quality control & Security */
  '数据指控&安全传递',
  /* 36.System driven patient management */
  '智能系统驱动病情管理',
  /* 37.EDC for RWS/RCT and more */
  'EDC助力RWS/RCT研究',
  /* 38.Digitized Administration */
  '数字化管理',
  /* 39.careers */
  '加入我们',
  /* 40.VISION */
  '愿景',
  /* 41.Stop cigarette from destroying our lives */
  '不再让烟草剥夺大家享有美好生活的权利',
  /* 42.OUR VALUES */
  '我们的价值观',
   /* 43.Innovation */
   '创新',
   /* 44.We are passionate... */
   '我们致力于开发最先进的科学技术,深入研究安全有效的数字疗法产品,帮助所有慢病患者安心治疗,让每个家庭远离疾病带来的痛苦。',
   /* 45.Performance */
   '追求卓越',
   /* 46.Our ability to... */
   '随着我们服务群体的多样化,我们对数字医疗领域的理解愈发深刻,对前沿技术的探索和运用能力也在不断突破。',
   /* 47.Ownership */
   '主人翁精神',
   /* 48.We never lose... */
   '探索一切可以改善人们生活质量的可能性,是每一位朗叶人的初心,我们愿意为之潜心笃志,砥砺前行。',
   /* 49.Curiosity */
   '保持好奇',
   /* 50.We are always... */
   '任何领域的创新都离不开好奇心,这种求知欲会驱动我们发现问题,解决问题。我们将永远保持着对世界的好奇去追寻更好的答案。',
   /* 51.OUR CULTURE */
   '我们的企业文化',
   /* 52.Knowledge Has No Limit */
   '学无止境',
   /* 53. */
   '数字医疗领域的变革和研发日新月异,我们始终秉承着对生命的敬畏和对未知的好奇不断学习着层出不穷的新的技术和知识,朗叶拥有最优秀的科学家、医生和开发者,我们的目标是继续努力学习并探索最先进的数字医疗技术,开发出新的的数字疗法产品。',
   /* 54. */
   '奋勇拼搏',
   /* 55. */
   '通往梦想的路途从来不会一帆风顺,作为开拓者更是需要在困难和逆境,甚至是失败中全力以赴、奋勇拼搏。在数字疗法的漫漫长路上,朗叶的每一位伙伴彼此照亮、互相鼓励,互相支持,互相关爱,一往无前的并肩前行。自强成就卓越,创新塑造未来。朗叶人将始终谦逊,勇于变革,坚持创新。',
   /* 56. */
   '积极阳光',
   /* 57. */
   '在朗叶,生活与工作完美平衡,我们在这里收获成长与快乐,智慧与友谊,也在这里感受着爱与美好。积极向上,团结有爱,健康乐观是我们每一位员工的生活态度,彼此之间相互支持和包容,朗叶大家庭就是我们最坚实可靠的后盾。我们将携手在这里开启一段激动人心的梦想之旅。',
   /* 58.Careers */
   '加入我们',
   /* 59.Contact */
   '联系我们',
   /* 60.News Room */
   '新闻',
   /* 61.Science */
   '科学技术'
]

export default DATA;
import {useState, useEffect} from 'react';
import AboutDetail from '../About';
import Modal from '@/components/Modal';
import cs from 'classnames';
import {ABOUT_DATA, APPROACH_DATA} from '@/mock';
import './index.scss'


type AboutProps = {
  id: string;
  className?: string;
}
const About = (props: AboutProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    }
  }, [])

  return (
    <div id={props.id} className='about-wrapper'>

      {/* about us */}
      <div className='about-contain'>

        <div className='about-contain-wrapper'>

          <section className='about-contain-section'>

            <h1><span>{ABOUT_DATA.header}</span></h1>

            <section>
              {ABOUT_DATA.section}
            </section>

            <Modal
              content={<AboutDetail />}
            >
              <div className='more-btn'>
                <div>
                  {/* <a href='/about' target='_blank'>{ABOUT_DATA.btnText}</a> */}
                  <span>{ABOUT_DATA.btnText}</span>
                </div>
              </div>
            </Modal>



          </section>

        </div>

        <div className={cs('about-group', {'show': show})}>
          <img className='about1' src={require('@/assets/images/about-1.png')} alt="about1" />
          <img className='about2' src={require('@/assets/images/about-2.png')} alt="about2" />
          <img className='about3' src={require('@/assets/images/about-3.png')} alt="about3" />
        </div>

      </div>

      {/* approach */}
      <div className='approach-wrapper'>
        <div className='approach-contain'>

          <header className='approach-contain-head'>
            {APPROACH_DATA.header}
          </header>

          <section className='approach-contain-article1'>

            <header className='approach-contain-article1-head'>
              {APPROACH_DATA.section[0].header}
            </header>

            <article className='approach-contain-article1-content'>
              {APPROACH_DATA.section[0].article}
            </article>

          </section>

          <section className='approach-contain-article2'>

            <header className='approach-contain-article2-head'>
              {APPROACH_DATA.section[1].header}
            </header>

            <article className='approach-contain-article2-content'>
              {APPROACH_DATA.section[1].article}
            </article>

          </section>

        </div>
      </div>

    </div>
  )
}

export default About
import {NEWS_DATA} from '@/mock';
import './index.scss';

const News = () => {

  const DATA = NEWS_DATA.slice(0, 3);


  return (
    <div className='ns-wrapper'>

      <header></header>

      <div className='ns-wrapper-container'>
        {
          DATA.map((item, index) => (
            <section className='ns-wrapper-container-item' key={item.id}>
              <div><img src={item.img} alt={`${item.id}`} /></div>
              <p><a
                href={item.href}
                target='_blank' rel="noreferrer">{item.content}</a></p>
            </section>
          ))
        }
      </div>

    </div>
  )
}

export default News;

import React, { ReactNode, cloneElement, useState, useEffect, CSSProperties } from 'react';
import './index.scss';


type ContentProps = {
  isShow: boolean;
  style?: CSSProperties;
  content?: ReactNode;
  goClose: () => void;
}
const Content = (props: ContentProps) => {
  const {isShow, content, goClose} = props;

  const style = {};

  function clickHandle() {
    console.log('....close.....');
    // goClose()
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.addEventListener('click', clickHandle)

    return () => {
      document.body.style.overflow = 'auto';
      document.body.removeEventListener('click', clickHandle);
    }
  }, [])

  return (
    <div className='modal-wrapper'>

      <div className='modal-container-close' onClick={() => {
        goClose()
      }}>
        <img src={require('@/assets/images/close.png')} />
      </div>

      <div
        className='modal-container'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >

        <div className='modal-content'>
          {content}
        </div>

      </div>

    </div>
  )
}


interface ModalProps {
  children?: ReactNode;
  content?: ReactNode;
}
const Modal = (props: ModalProps) => {
  const {children, ...otherProps} = props;
  const [isShow, setIsShow] = useState(false);

  const goSwitch = () => {
    isShow ? goClose() : goOpen();
  }
  const goClose = () => {
    console.log('....close');
    setIsShow(false);
  }

  const goOpen = () => {
    console.log('....open');
    setIsShow(true);
  }


  return (
    <>
      <div style={{width: "254px"}} onClick={goSwitch}>{children}</div>

      {isShow &&
        cloneElement(
          <Content isShow={isShow} goClose={goClose} />,
          {
            key: 'modal_content',
            ...otherProps
          }
        )
      }
    </>
  )
}

export default React.memo(Modal);
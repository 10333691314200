import { useState } from 'react';
import cs from 'classnames';
import './index.scss';

type LayeroverItem = {
  id: string;
  content?: any;
  desc?: string;
  showImg?: string;
  selectImg?: string;
  href?: string;
}
type LayeroverProps = {
  sourceData: LayeroverItem[]
}
const Layerover = (props: LayeroverProps) => {
  const { sourceData = [] } = props;
  const [selected, setSelected] = useState<any>({});
  const [selectId, setSelectId] = useState('')


  return (
    <ul className='lo-wrapper'>
      {
        sourceData.map((item, index) => {
          const defaultStyle = {
            backgroundImage: `url(${item.showImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '105%',
            backgroundPosition: '-10px -20px'
          };

          const selectStyle = {
            backgroundImage: `url(${item.selectImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '110%',
            backgroundPosition: '-20px -20px'
          };

          return (
            <li
              key={item.id}
              className={cs('lo-item', {'lo-selected': selectId === item.id})}
              // onMouseEnter={() => { setSelected(item); }}
              // onMouseOut={() => { setSelected({}); }}
              onMouseOver={() => setSelectId(item.id)}
              onMouseOut={() => setSelectId('')}
            >
              <div style={{...defaultStyle}} className='lo-item-default'>
                <section className='lo-item-default-desc'>{item.content}</section>
              </div>
              <div style={selectStyle} className='lo-item-selected'>
                <div className='lo-item-selected-content'>
                  <header className='lo-item-selected-content-head'>{item.content}</header>
                  <section className='lo-item-selected-content-desc'>{item.desc}</section>
                  <div className='lo-item-selected-content-btn'>
                    <a
                      // href={`/detail?newId=${item.id}&index=${index}`}
                      href={item.href}
                      target='_blank' rel="noreferrer">
                      <img width='23px' src={require('@/assets/images/news/jiantou.png')} alt='detail' />DETAIL
                    </a>
                  </div>
                </div>
              </div>
            </li>
          )
        })
      }

      {/* {
        <li
          className={cs('lo-item')}
        >
          <div style={{
            backgroundImage: `url(${require('@/assets/images/news/empty.png')})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '110%',
            backgroundPosition: '-10px -20px'
          }} className='lo-item-default lo-default'>

            <section className='lo-item-default-desc' style={{fontSize: '22px'}}>
              To be continued...
            </section>

          </div>

        </li>
      } */}
    </ul>
  )
}

export default Layerover;
import {NEWS_DATA, NEWS} from '@/mock';
import Layerover from '@/components/Layerover';
import './index.scss';


type NewsProps = {
  id: string;
}
const News = (props: NewsProps) => {

  return (
    <div id={props.id} className='news-wrapper'>

      <div className='news-contain'>

        <div className='news-contain-more'>
          <a href='/news' target='_blank'>{NEWS.btnText}</a>
        </div>

        <div className='news-contain-content'>
          <Layerover sourceData={NEWS_DATA} />
        </div>

      </div>

    </div>
  )
}

export default News;
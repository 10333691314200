import {useState} from 'react';
import cs from 'classnames';
import './index.scss';

type OptionType = {
  id: number;
  key: string;
  title: string;
  content: string;
  icon?: string;
}
type SwitchoverProps = {
  sourceData: OptionType[]
}
const Switchover = (props: SwitchoverProps) => {
  const {sourceData = []} = props;
  const [option, setOption] = useState<OptionType>(sourceData[0]);

  return (
    <div className='so-wrapper'>

      <div className='so-container'>
      {
        sourceData.map(item => (
          <div
            key={item.id}
            className={cs('so-nav', {'selected': item.id === option.id})}
            onMouseOver={() => {
              setOption(item);
            }}
          >

            <div className='so-nav-item'>
              <div className='so-nav-item-icon'>
                <img src={item.icon} alt={item.key} />
              </div>
              <h3 className='so-nav-item-title'>{item.title}</h3>
            </div>

          </div>
        ))
      }
      </div>

      <section className='so-content-wrapper'>
        <div className='so-content'>{option?.content}</div>
      </section>

    </div>
  )
}

export default Switchover;
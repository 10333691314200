import {useEffect, useState} from 'react';
import Nav, {NavItem} from '@/components/Nav';
import {NAV_LIST} from '@/mock';
import Logo from '@/logo.jpg';
import './index.scss';


const Header = () => {
  const [selectedId, setSelectedId] = useState('about');

  useEffect(() => {
    window.onscroll = function() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop, 'selectedId', selectedId);
      if(scrollTop < 1500) {
        setSelectedId('about');
      }

      if(1500 < scrollTop && scrollTop < 2600) {
        setSelectedId('science')
      }

      if(2600 < scrollTop && scrollTop < 4600) {
        setSelectedId('news')
      }

      if(4600 < scrollTop && scrollTop < 6600) {
        setSelectedId('careers')
      }

      if(6600 < scrollTop) {
        setSelectedId('contact')
      }
    }
  }, [])

  return (
    <header className='header'>

      <div className='logo'>

        <img src={Logo} alt='Leaflong' />

      </div>

      <Nav className='header-nav' onSuccess={(params?: NavItem) => {
        const {id = ''} = params || {};
        if (['en', 'cn'].includes(id)) {
          localStorage.setItem('lang', id);
        }
        setSelectedId(id);
      }} sourceData={NAV_LIST} id={selectedId} />

    </header>
  )
}

export default Header;
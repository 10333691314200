import {ABOUT_INFO} from '@/mock';
import './index.scss';

const About = () => {

  return (
    <div className='ab-wrapper'>

      <div className='ab-container'>

        <div className='abs abs1'>

          <section className='desc'>
            {ABOUT_INFO[0]}
          </section>

          <div className='img-wrapper'>
            <img className='img' src={require('@/assets/images/about01.png')} alt="" />
          </div>

        </div>

        <div className='abs abs2'>

          <div className='img-wrapper'>
            <img className='img' src={require('@/assets/images/about02.png')} alt="" />
          </div>

          <section className='desc'>
            {ABOUT_INFO[1]}
          </section>

        </div>

      </div>

    </div>
  )
}

export default About;
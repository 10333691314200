import { useEffect, useState } from 'react';
import cs from 'classnames';
import './index.scss';


type SliderItem = {
  id: number | string;
  header: string;
  content: string;
}
type SliderProps = {
  sourceData: SliderItem[];
}
const Slider = (props: SliderProps) => {
  const sourceData = props.sourceData;
  const count = Array.from(Array(sourceData.length).keys());
  const [currentCount, setCurrentCount] = useState(0);
  const [isCustom, setIsCustom] = useState(true);
  const [timer, setTimer] = useState<any>();

  const switchPro = () => {
    console.log('pro');
    addHandle(-1);
  }

  const switchNext = () => {
    console.log('next')
    addHandle();
  }

  const addHandle = (register = 1) => {
    if(currentCount === sourceData.length - 1 && register > 0) {
      return setCurrentCount(0);
    }
    if(currentCount === 0 && register < 0) {
      return setCurrentCount(sourceData.length - 1);
    }
    setCurrentCount(currentCount + register);
  }

  const initTime = (custom = false) => {
    if(custom) {
      return setTimeout(() => {
        addHandle()
      }, 5000);
    }
    if(!isCustom) return;
    return setTimeout(() => {
      addHandle()
    }, 5000);
  }

  const clearTime = () => {
    clearTimeout(timer);;
  }

  useEffect(() => {
    setTimer(initTime())
    return clearTime;
  }, [currentCount])

  return (
    <div className='slider-wrapper'>

      <div
        className='slider-wrapper-mask'
        onMouseEnter={() => {
          setIsCustom(false);
          clearTime();
        }}
        onMouseLeave={() => {
          setIsCustom(true);
          initTime(true);
        }}
      >
        <ul className='slider-wrapper-content'>
          <li onClick={switchPro} className='slider-wrapper-content-before'></li>
          {
            sourceData.map((item, index) => (

              <li key={`${item.id}_${index}`} className={cs('slider-wrapper-content-item', {'select': index === currentCount})}>

                <header>{item.header}</header>

                <section>{item.content}</section>

              </li>

            ))
          }
          <li onClick={switchNext} className='slider-wrapper-content-after'></li>
        </ul>
      </div>

      <ul
        className='slider-wrapper-footer'
        onMouseEnter={() => {
          setIsCustom(false);
          clearTime();
          console.log('..........ul')
        }}
        onMouseLeave={() => {
          setIsCustom(true);
          initTime(true);
          console.log('..........li')
        }}
      >
        {
          count.map((index) => (
            <li
              key={`slider-btn-${index}`}
              onClick={() => {
                setCurrentCount(index);
              }}

            ><div className={cs('slider-btn', {'select': index === currentCount})}></div></li>
          ))
        }
      </ul>

    </div>
  )
}

export default Slider;
import cs from 'classnames';
import {TRANSLATE_DATA} from '@/mock';
import './index.scss';

export type NavItem = {
  id: string;
  name: string | React.ReactElement;
  path: string;
  sub?: NavItem[];
}
type NavProps = {
  id: string;
  sourceData: NavItem[];
  className?: string;
  style?: StyleSheetList;
  onSuccess?: (params?:NavItem) => void;
}
const Nav = (props: NavProps) => {
  const {id, sourceData, className, onSuccess} = props;
  const translateNav = {
    ...TRANSLATE_DATA,
    name: <img className='nav-container-item-content-translate' src={require('@/assets/images/fanyi.png')} alt="translate" />
  }
  const selectStyle = {
    fontWeight: 600,
    color: '#56C1CC'
  }

  return (
    <nav className={`${className} nav-wrapper`}>


      <ul className='nav-container'>

        {[...sourceData, translateNav].map(nav => {
          const isHasSub = !!(nav.sub && nav.sub.length);
          const isHasSubAsSelect = (isHasSub && nav.sub?.some(n => n.id === id)) || nav.id === id;

          return (<li key={nav.id} onClick={() => {
            if(isHasSub) return;
            onSuccess && onSuccess(nav);
          }} className={cs('nav-container-item', {'item-subnav': isHasSub})}>
            {isHasSub ?
              <span
                style={isHasSubAsSelect ? selectStyle : {}}
                className='nav-container-item-content nav-text'
              >{nav.name}</span>
              :
              <a href={nav.path} target='_self'><span
                style={nav.id === id ? selectStyle : {}}
                className='nav-container-item-content nav-text'
              >{nav.name}</span></a>
            }

            {nav.sub && nav.sub.length &&

              <ul className='nav-container-item-subnav'>

                {nav.sub.map(subnav => (

                  <li key={subnav.id} onClick={() => {
                    onSuccess && onSuccess(subnav);
                  }} className='nav-container-item-subnav-item'>

                    <a href={subnav.path} target='_self'><span
                      style={subnav.id === id ? selectStyle : {}}
                      className='nav-container-item-subnav-item-content nav-text'
                    >{subnav.name}</span></a>

                  </li>

                ))}

              </ul>

            }

          </li>)

        })}

      </ul>

    </nav>
  )
}

export default Nav;
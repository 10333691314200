import CN_DATA from '@/mock_cn';

const LongType = localStorage.getItem('lang');

export const onTranslate = (text: string, index: number) => {
  // 中英文
  if(LongType === 'cn') {
    return CN_DATA[index] || '';
  }

  return text;
}
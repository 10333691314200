import Header from '@/components/Header';
import Slider from '@/components/Slider';
import { CAREERS, CAREERS_DATA, CULTURE_DATA } from '@/mock';
import cs from 'classnames';
import { useState } from 'react';
import About from './About';
import News from './News';
import { ScienceSolution, ScienceTechnology } from './Science';
import './index.scss';

const Home = () => {
  const [selectId, setSelectId] = useState('');
  const isCN = window.location.host.search('.cn') > -1;
  console.log('isCN >>>>>>>', isCN);

  return (
    <div>
      <Header />

      {/* about */}
      <About id="about" />

      {/* science */}
      <ScienceTechnology id="technology" />
      <ScienceSolution id="products" />

      {/* news */}
      <News id="news" />

      {/* careers our values */}
      <div id="values" className="careers-wrapper">
        <div className="careers-head">
          <div className="careers-head-container">
            <img
              src={require('@/assets/images/careers/header.png')}
              alt="header"
            />
            <h2>{CAREERS[0]}</h2>
            <p>{CAREERS[1]}</p>
          </div>
        </div>

        <div className="careers-content">
          <header>
            <span className="careers-content-subtitle">{CAREERS[2]}</span>
            <h3 className="careers-content-title">{CAREERS[3]}</h3>
          </header>

          <div className="careers-content-contain">
            {CAREERS_DATA.map((item, index) => (
              <div
                key={item.id}
                className={cs('careers-content-contain-item', {
                  selected: selectId === item.id,
                })}
                onMouseOver={() => setSelectId(item.id)}
                onMouseOut={() => setSelectId('')}
              >
                <div
                  className={cs('item-wrapper-focus', {
                    'selected-focus': selectId === item.id,
                  })}
                >
                  <header>{item.name}</header>
                  <section>{item.desc}</section>
                </div>
                <div
                  className={cs('item-wrapper-default', {
                    'selected-default': selectId !== item.id,
                  })}
                >
                  <div>
                    <img
                      className={`careers-content-contain-img img${index}`}
                      src={item.img}
                      alt={item.id}
                    />
                  </div>
                  <p className="careers-content-contain-desc">{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* careers our culture */}
      <div id="culture" className="careers-culture-wrapper">
        <div className="careers-content">
          <header>
            <span className="careers-content-subtitle">{CAREERS[2]}</span>
            <h3 className="careers-content-title">{CAREERS[4]}</h3>
          </header>

          <div className="careers-content-contain">
            <div className="careers-culture-content">
              <Slider sourceData={CULTURE_DATA} />
            </div>
          </div>
        </div>
      </div>

      {/* founder */}
      <div className="founder-container">
        <div className="top-container">
          <div className="content">
            <div className="enTxt">CAREERS</div>
            <div className="chinaTxt">Company Founder</div>
          </div>
        </div>
        <div className="bottom-container">
          <div className="content">
            <div className="left">
              <div className="name">
                <span className="bigName">沈晔 </span>/ Jack Shen
              </div>
              <div className="desc">Founder & CEO</div>
              <div className="divide">
                <div className="divide1"></div>
              </div>
              {/* <div className="personDesc" style={{ marginBottom: 12 }}>
                沈晔是医疗人工智能方面的专家，是国内领先的AI呼吸疾病解决方案平台“朗叶医疗”的创始人兼
                CEO。此前他在知名美元基金LHC和VKC
                主导医疗和科技投资。他曾在George
                Church共同创立的细胞治疗独角兽公司担任资深顾问。
              </div>
              <div className="personDesc">
                他拥有MIT和UC Berkeley
                的硕士本科学位，在校从事机器学习和生信分析方向的研究。基于近
                15年在生命科学、AI和 资本市场的经验， 沈晔致力于通过
                AI技术来推动医疗行业的变革。
              </div> */}
              <div className="personDesc">
                <div className="contentItem">
                  <div className="dot"></div>
                  <div>
                    Healthcare tech services and TMT venture investment veteran
                  </div>
                </div>
                <div className="contentItem">
                  <div className="dot"></div>
                  <div>
                    Multiple years executive operational experience in NGS and
                    biotech startups
                  </div>
                </div>
                <div className="contentItem">
                  <div className="dot"></div>
                  <div>
                    Experienced machine learning and bioinformatics practitioner
                    in healthcare and telecom fields
                  </div>
                </div>
                <div className="contentItem">
                  <div className="dot"></div>
                  <div>
                    Holds degrees from UC Berkeley and MIT in Statistics and
                    Quant Finance
                  </div>
                </div>
              </div>
            </div>
            <img
              src={require('@/assets/images/founderIcon.png')}
              alt=""
              className="icon"
            />
          </div>
        </div>
      </div>

      {/* contact */}
      <div id="contact" className="contact-wrapper">
        <div className="contact-content">
          <header className="contact-content-head">{CAREERS[5]}</header>

          <ul className="contact-content-detail">
            <li>
              <img src={require('@/assets/images/partnership.png')} alt="" />
              <span>partnership@leaflongtx.com</span>
            </li>

            <li>
              <img src={require('@/assets/images/hr.png')} alt="" />
              <span>hr@leaflongtx.com</span>
            </li>

            <li>
              <img src={require('@/assets/images/address.png')} alt="" />
              <span>上海市杨浦区创智天地企业中心3号楼</span>
            </li>

            <li>
              <img src={require('@/assets/images/address.png')} alt="" />
              <span>杭州市余杭区浙富西溪堂6号楼</span>
            </li>

            <li className="contact-content-address-wrapper">
              <img
                className="contact-content-address"
                src={require('@/assets/images/map.png')}
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>

      <footer>
        ©2022 Leaflong Therapeutics Co. Ltd. All Rights Reserved.
        <div className="home-about-ban">
          <a href="https://beian.miit.gov.cn/" target="_blank">
            {isCN ? '浙ICP备2022032937号-1' : '沪ICP备2022009719号-1'}
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Home;
